<template>
  <div class="main">
    <div class="main__menu">
      <Menu @section-select="onSectionSelect" :await-for-live-games="true" :are-live-games="areLiveGames" />
    </div>
    <div :class="selectedComponent === 'RightBet' ? 'main__container' : 'main__container2'">
      <KeepAlive>
        <Component
          :is="selectedComponent"
          :await-for-data="awaitForRightBetData"
          :right-bet-tournaments="rightBetTournaments"
          :parlay-bets="parlayBets"
          is-full
          @tournament-change="$emit('tournament-change', $event)"
        />
      </KeepAlive>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'Main',
  components: {
    Menu: () => import('@/components/Sections/GolTipster-v2/General/Menu'),
    RightBet: () => import('@/components/Sections/GolTipster-v2/RightBet/Main'),
    Parlay: () => import('@/components/Sections/GolTipster-v2/Parlay/Main'),
    Tips: () => import('@/components/Sections/GolTipster-v2/Tips/Main'),
  },
  props: {
    awaitForRightBetData: {
      type: Boolean,
      default: false,
    },
    rightBetTournaments: {
      type: Array,
      default: () => [],
    },
    parlayBets: {
      type: Object,
      default: () => ({}),
    },
    areLiveGames: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedComponent: '',
      intervalId: null,
      intervalCounter: 0,
    };
  },
  methods: {
    ...mapMutations('betsGeneral', ['setRightBetComponent', 'setRefreshIntervalCounter', 'setSelectedGame']),
    setUpRefreshInterval() {
      const timeout = 1000 * 60 * 5;
      setInterval(() => {
        this.intervalCounter++;
        this.setRefreshIntervalCounter(this.intervalCounter);
      }, timeout);
    },
    onSectionSelect(sectionComponent) {
      if (sectionComponent !== 'RightBet') {
        this.setRightBetComponent('');
      }
      this.selectedComponent = sectionComponent;
    },
  },
  created() {
    this.intervalId = this.setUpRefreshInterval();
  },
  beforeDestroy() {
    this.setSelectedGame({
      awayTeam: {},
      homeTeam: {},
    });
    clearInterval(this.intervalId);
    this.setSelectedGame();
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style scoped lang="scss">
.main {
  width: 100%;
  box-shadow: 0 7px 24px 0 rgba(84, 124, 171, 0.12);
  background-color: #fff;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__menu {
    width: 100%;
    @media screen and (max-width: 64.5rem) {
      padding: 0.4rem;
    }
  }

  &__container {
    max-width: 64.5rem;
    width: 100%;
    padding: 0 0.5rem;

    @media screen and (max-width: 486px) {
      padding: 0;
    }
  }
  &__container2 {
    max-width: 74.5rem;
    width: 100%;
    padding: 0 0.5rem;

    @media screen and (max-width: 486px) {
      padding: 0;
    }
  }
}
</style>
